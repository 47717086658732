import { AxiosResponse } from 'axios';
import { AccountUser } from '../data/models/AccountUser/user.model';
import http from './../../http-common';
import TokenService from './token.service';

class UserAdminService {
  public ping(): Promise<AxiosResponse<any>> {
    return http.get('/ping');
  }

  public login(username: string, password: string): Promise<AxiosResponse<any>> {
    let data = {
      'username': username,
      'password': password
    }
    return http.post('/api/login', data);
  }

  public protected(): Promise<AxiosResponse<any>> {
    return http.get('/api/protected', { headers: TokenService.getAuthHeader() });
  }

  public refreshToken(): Promise<AxiosResponse<any>> {
    return http.post('/api/refresh', {}, { headers: TokenService.getAuthHeader() });
  }

  public getAllClient(): Promise<AxiosResponse<any>> {
    return http.get('/api/clients', { headers: TokenService.getAuthHeader() });
  }

  public getRights(): Promise<AxiosResponse<any>> {
    return http.get('/api/rights', { headers: TokenService.getAuthHeader() });
  }

  public getUsersFromClient(id: string): Promise<AxiosResponse<any>> {
    return http.get(`/api/client/${id}/users`, { headers: TokenService.getAuthHeader() });
  }

  public getUserFromClient(client_id: string, user_id: string): Promise<AxiosResponse<any>> {
    return http.get(`/api/client/${client_id}/user/${user_id}`, { headers: TokenService.getAuthHeader()});
  }

  public createUser(client_id: string, user_data: AccountUser): Promise<AxiosResponse<any>> {
    let data = {
      'id': null,
      'firstname': user_data.firstName,
      'lastname': user_data.lastName,
      'email': user_data.mail,
      'role': {
        'scope': user_data.role.scope,
        'targets': user_data.role.targets,
        'resources': {
          'POI': user_data.storeCodes,
          'POI_GROUP': user_data.groups
        }
      }
    };
    return http.post(`/api/client/${client_id}/user`, data, { headers: TokenService.getAuthHeader() });
  }

  public createUsersFromExcel(client_id: string, users_data: Array<AccountUser>){
    var data = users_data.map(user_data => 
      // eslint-disable-next-line no-new-object
      new Object({
        'id': null,
        'firstname': user_data.firstName,
        'lastname': user_data.lastName,
        'email': user_data.mail,
        'role': {
          'scope': user_data.role.scope,
          'targets': user_data.role.targets,
          'resources': {
            'POI': user_data.storeCodes,
            'POI_GROUP': user_data.groups
          }
        }
      }))
    return http.post(`/api/client/${client_id}/usersFromExcel`, data, { headers: TokenService.getAuthHeader() })
    .then(response => {
      window.alert("Utilisateurs créés");
    })
    .catch(error => {
      window.alert("Erreur lors de la création d'utilisateurs, avec le message :"+error);
    });
  }

  public sendSsoFile(client_id: string, ssoFile: File): Promise<AxiosResponse<any>> {
    let formData = new FormData();
    formData.append('ssoFile', ssoFile);

    return http.post(`/api/client/${client_id}/ssoUsers`,
                     formData,
                     { headers: {
                          'Content-Type': 'multipart/form-data', 
                          ...TokenService.getAuthHeader()
                      }
                     });
  }

  updateUser(client_id: string, user_id: string, user_data: AccountUser) {
    let data = {
      'id': user_id,
      'firstname': user_data.firstName,
      'lastname': user_data.lastName,
      'email': user_data.mail,
      'role': {
        'scope': user_data.role.scope,
        'targets': user_data.role.targets,
        'resources': {
          'POI': user_data.storeCodes,
          'POI_GROUP': user_data.groups
        }
      }
    };
    return http.put(`/api/client/${client_id}/user/${user_id}`, data, { headers: TokenService.getAuthHeader() });
  }

  public deleteUser(client_id: string, ids: string[]): Promise<AxiosResponse<any>> {
    return http.post(`/api/client/${client_id}/user/delete`, {userIds: ids}, { headers: TokenService.getAuthHeader() });
  }

  public resetPasswords(ids: string[]): Promise<AxiosResponse<any>> {
    return http.post('/api/users/reset', {userIds: ids}, { headers: TokenService.getAuthHeader()});
  }

  public activateUser(ids: string[]): Promise<AxiosResponse<any>> {
    return http.post('/api/users/activate', {shouldEnable: true, userIds: ids}, { headers: TokenService.getAuthHeader() });
  }

  public deactivateUser(ids: string[]): Promise<AxiosResponse<any>> {
    return http.post('/api/users/activate', {shouldEnable: false, userIds: ids}, { headers: TokenService.getAuthHeader() });
  }
}

export default new UserAdminService();