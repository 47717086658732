import React, { Component } from 'react';
import './Checkbox.scss'


export interface Props {
  disable: boolean;
  checkedValue?: boolean;
  onChange?: {};
}
interface State{
  isValueCheck?: boolean;
}

export default class Checkbox extends React.Component<Props, State> {

  constructor(props:Props) {
    super(props);
    this.setState({isValueCheck: this.props ? this.props.checkedValue : false});
    this.onchange.bind(this);
  }

  onchange() {
    if(this.props.checkedValue) {
      this.setState({isValueCheck: this.props.checkedValue});
    }
  }

  render() {
    return (
        <div className="custom-checkbox">
        <input type="checkbox" className="input-checkbox" id="selection" checked={this.props.checkedValue?this.props.checkedValue:undefined} disabled={this.props.disable}/>
        <span className="custom_checkbox_control">
          <i className="material-icons">check</i>
        </span>
      </div>
    );
  }
}