import React from 'react';
import * as XLSX from 'xlsx'
import Checkbox from '../custom-components/checkbox/Checkbox';
import DropdownMenu from '../custom-components/dropdown/DropdownMenu';
import Modal from '../custom-components/modal/modal';
import history from '../../../history';
import userAdminService from '../../../services/user-admin.service';
import './user-admin-list.component.scss'


import temp_password_icon from './../../../../assets/img/element_picto_interface_user_mdp_temporaire.png'
import valid_password_icon from './../../../../assets/img/element_picto_interface_user_mdp_valide.png'
import delete_cross from './../../../../assets/img/element_croix_rouge_supprim.png'
import modify_icon from './../../../../assets/img/element_modification_grey_v2.png'
import { AccountUser } from '../../../data/models/AccountUser/user.model';
import { RoleDetail, RoleLevel, UserStatus } from '../../enum/UserEnum';
import { FileDrop } from '../custom-components/fileUploader/fileUploader';


const PasswordFilter = {
  ALL: 1,
  DEFINED: 2,
  UNDEFINED: 3
}

const RoleFilter = {
  ALL: 1,
  GLOBAL: 2,
  LOCAL: 3
}

const actionActiveMenuList: string[] = [
  'Désactiver un utilisateur',
  'Exporter les stores codes d\'un utilisateur',
  'Réinitialiser le mot de passe',
  'Supprimer un utilisateur'
];
const actionInactiveMenuList: string[] = [
  'Activer un utilisateur',
  'Exporter les stores codes d\'un utilisateur',
  'Réinitialiser le mot de passe',
  'Supprimer un utilisateur'
];

const importUsersOptionList: string[] = [
  'SSO',
  'Standard'
]

export interface Props {
  list: any[];
  key: string;
  isUsersActive: boolean;
  updateData: () => void;
  client: string;
}
  
interface State {
  currentList: any[],
  selectedUsers: AccountUser[],
  passwordFilter: number,
  roleFilter: number,
  selectAll: boolean,
  showModal: boolean;
  disableActions: boolean;
  modalAction?: () => void;
  modalEnabledAction: string;
  modalTitle: string;
  modalContent: any;
  idxPasswordFilter: number;
  idxRoleFilter: number;
  inputFilter: string;
  usersToImport: AccountUser[];
  ssoExcelFile: File | null;
}

class UserAdminListFragment extends React.Component<Props, State> {
  dropdownRef: React.RefObject<DropdownMenu>;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentList : this.props.list,
      selectedUsers: [],
      passwordFilter: PasswordFilter.ALL,
      roleFilter: RoleFilter.ALL,
      selectAll: false,
      showModal: false,
      disableActions: true,
      modalEnabledAction: '',
      modalTitle: '',
      modalContent: '',
      idxPasswordFilter : 0,
      idxRoleFilter: 0,
      inputFilter: '',
      usersToImport:[],
      ssoExcelFile: null
    };
    this.dropdownRef = React.createRef();
    this.handleDropdowns = this.handleDropdowns.bind(this);
    this.clientHasChanged = this.clientHasChanged.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);
    this.handlePasswordFilter = this.handlePasswordFilter.bind(this);
    this.handleRoleFilter = this.handleRoleFilter.bind(this);
    this.updateInfos = this.updateInfos.bind(this);
    this.select = this.select.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.activateUsers = this.activateUsers.bind(this);
    this.deactivateUsers = this.deactivateUsers.bind(this);
    this.deleteUsers = this.deleteUsers.bind(this);
    this.getSelection = this.getSelection.bind(this);
    this.readUsersExcelFile = this.readUsersExcelFile.bind(this);
    this.readSsoUsersExcelFile = this.readSsoUsersExcelFile.bind(this);
    this.convertToAccountUsers = this.convertToAccountUsers.bind(this);
    this.importStandardUsersFromExcel = this.importStandardUsersFromExcel.bind(this);
    this.importSsoUsersFromExcel = this.importSsoUsersFromExcel.bind(this);
  }

  public clientHasChanged(): void {
    this.setState({ currentList: this.props.list });
  }

  public handleSearchFilter = (input: any) => {
    let inputValue = input.target.value;
    this.setState({
      inputFilter: inputValue,
      currentList: this.props.list.filter(user => {
        if (user.user.firstName.toLowerCase().includes(inputValue)
          || user.user.lastName.toLowerCase().includes(inputValue)
          || user.user.mail.toLowerCase().includes(inputValue)
        ) { return user; }
      })
    })
  }
  
  public handlePasswordFilter = (event: any) => {
    if (event.target.options) {
      let index = event.target.options.selectedIndex;
      this.setState({passwordFilter: index}, this.updateInfos);
    }
  }

  public handleRoleFilter = (event: any) => {
    if (event.target.options) {
      let index = event.target.options.selectedIndex;
      this.setState({roleFilter: index}, this.updateInfos);
    }
  }

  public updateInfos(): void {
    if (this.state.passwordFilter === PasswordFilter.DEFINED && this.state.roleFilter === RoleFilter.ALL) {
      this.setState({ currentList: this.props.list.filter(user => user.user.status !== UserStatus.FORCE_CHANGE_PASSWORD) });
    } else if (this.state.passwordFilter === PasswordFilter.UNDEFINED && this.state.roleFilter === RoleFilter.ALL) {
      this.setState({ currentList: this.props.list.filter(user => user.user.status === UserStatus.FORCE_CHANGE_PASSWORD) });
    } else if (this.state.passwordFilter === PasswordFilter.ALL && this.state.roleFilter === RoleFilter.GLOBAL) {
      this.setState({ currentList: this.props.list.filter(user => user.user.role.scope === 'global') });
    } else if (this.state.passwordFilter === PasswordFilter.ALL && this.state.roleFilter === RoleFilter.LOCAL){
      this.setState({ currentList: this.props.list.filter(user => user.user.role.scope === 'local') });
    } else if (this.state.passwordFilter === PasswordFilter.DEFINED && this.state.roleFilter === RoleFilter.GLOBAL) {
      this.setState({ currentList: this.props.list.filter(user => user.user.status !== UserStatus.FORCE_CHANGE_PASSWORD && user.user.role.scope === 'global') });
    } else if(this.state.passwordFilter === PasswordFilter.DEFINED && this.state.roleFilter === RoleFilter.LOCAL){
      this.setState({ currentList: this.props.list.filter(user => user.user.status !== UserStatus.FORCE_CHANGE_PASSWORD && user.user.role.scope === 'local') });
    } else if(this.state.passwordFilter === PasswordFilter.UNDEFINED && this.state.roleFilter === RoleFilter.GLOBAL){
      this.setState({ currentList: this.props.list.filter(user => user.user.status === UserStatus.FORCE_CHANGE_PASSWORD && user.user.role.scope === 'global') });
    } else if(this.state.passwordFilter === PasswordFilter.UNDEFINED && this.state.roleFilter === RoleFilter.LOCAL){
      this.setState({ currentList: this.props.list.filter(user => user.user.status === UserStatus.FORCE_CHANGE_PASSWORD && user.user.role.scope === 'local') });
    } else {
      this.setState({ currentList: this.props.list });
    }
    this.setState({ inputFilter: '' });
  }

  public selectAll(selectAll: boolean): void {
    this.setState({
      selectAll: !selectAll,
      currentList : this.state.currentList.map((user: any) => {
        user.select = !this.state.selectAll;
        return user;
      })
    }, this.getSelection);
  }

  public select(id: string): void {
    this.setState({
      currentList : this.state.currentList.map((user: any) => {
        if (user.user.id === id) {
          user.select = !user.select;
        }
        return user;
      }),
      selectAll: this.state.selectedUsers.length === this.state.currentList.length ? true : this.state.selectAll
    }, this.getSelection);
  }

  public getSelection(): void {
    this.setState({ selectedUsers : [] })
    this.state.currentList.map((usr: any) => {
      if (usr.select === true) {
        this.state.selectedUsers.push(usr.user);
      }
    });
    this.setState(
      { disableActions: this.state.selectedUsers.length === 0 ? true : false }
    );
  }

  public handleDropdowns(actionName?: string): void {
    switch (actionName) {
      case 'Désactiver un utilisateur': {
        this.setState({
          ...this.state,
          modalEnabledAction: actionName,
          modalTitle: 'Êtes-vous certains de vouloir désactiver cet utilisateur ?',
          modalAction: this.deactivateUsers
        });
        break;
      }
      case 'Activer un utilisateur': {
        this.setState({
          ...this.state,
          modalEnabledAction: actionName,
          modalTitle: 'Êtes-vous certains de vouloir activer cet utilisateur ?',
          modalAction: this.activateUsers
        });
        break;
      }
      case 'Réinitialiser le mot de passe': {
        this.setState({
          ...this.state,
          modalEnabledAction: actionName,
          modalTitle: 'Êtes-vous certains de vouloir réinitialiser le mot de passe de cet utilisateur ?',
          modalAction: this.resetPassword
        });
        break;
      }
      case 'Exporter les stores codes d\'un utilisateur': {
        this.setState({
          modalEnabledAction: actionName,
          // TODO: Export Excel
          modalTitle: 'Coming soon..',
          modalAction: this.exportUsers
        });
        break;
      }
      case 'Supprimer un utilisateur': {
        this.setState({
          modalEnabledAction: actionName,
          modalTitle: 'Êtes-vous certains de vouloir supprimer cet utilisateur ?',
          modalAction: this.deleteUsers
        });
        break;
      }
      case 'SSO': {
        this.setState({
          modalEnabledAction: actionName,
          modalTitle: "Importer des utilisateurs SSO",
          modalContent: <FileDrop readFile={this.readSsoUsersExcelFile}></FileDrop>,
          modalAction: this.importSsoUsersFromExcel
        });
        break;
      }
      case 'Standard': {
        this.setState({
          modalEnabledAction: actionName,
          modalTitle: "Importer des utilisateurs standards",
          modalContent: <FileDrop readFile={this.readUsersExcelFile}></FileDrop>,
          modalAction: this.importStandardUsersFromExcel
        });
        break;
      }
      default: {
        break;
      }
    }
    this.setState({ showModal: !this.state.showModal });
  }

  public resetPassword(): void {
    this.getSelection();
    userAdminService.resetPasswords(this.state.selectedUsers.map((user) => { return user.id; }));
    this.props.updateData();
  }

  public deactivateUsers(): void {
    this.getSelection();
    userAdminService.deactivateUser(this.state.selectedUsers.map((user) => { return user.id; }));
    this.props.updateData();
  }

  public activateUsers(): void {
    this.getSelection();
    userAdminService.activateUser(this.state.selectedUsers.map((user) => { return user.id; }));
    this.props.updateData();
  }

  public deleteUsers(): void {
    this.getSelection();
    userAdminService.deleteUser(this.props.client, this.state.selectedUsers.map((user) => { return user.id; }));
    this.props.updateData();
  }

  public exportUsers(): void { }

  readUsersExcelFile(file: any){
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e?.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Accéder aux feuilles de calcul
      const accountSheetName = workbook.SheetNames[0];
      const accountWorksheet = workbook.Sheets[accountSheetName];

      const permissionSheetName = workbook.SheetNames[1];
      const permissionWorksheet = workbook.Sheets[permissionSheetName];

      // Convertir la feuille de calcul en un objet JSON
      const accountData = XLSX.utils.sheet_to_json(accountWorksheet, { raw: false });
      const permissionData = XLSX.utils.sheet_to_json(permissionWorksheet, { raw: false });
      this.setState({usersToImport: this.convertToAccountUsers(accountData, permissionData)});
    };

    reader.readAsArrayBuffer(file);
  }

  readSsoUsersExcelFile(file: File){
    this.setState({ssoExcelFile: file});
  }

  convertToAccountUsers(accountData: any, permissionData: any) : AccountUser[] {
    return accountData.map((account:any) => {
      let user: AccountUser = {
        id: '',
        firstName: account.firstname,
        lastName: account.lastname,
        username: account.nameid || account.email,
        mail: account.email,
        role: {
          scope: account.global_roles === RoleDetail.ADMIN.toLowerCase() ? RoleLevel.GLOBAL.toLowerCase() : RoleLevel.LOCAL.toLowerCase(),
          targets : account.global_roles === RoleDetail.ADMIN.toLowerCase()  ? [RoleDetail.ADMIN.toLowerCase()] : permissionData.filter((permission: any) => permission.email == account.email).map((permission: any) => permission.role)
        },
        storeCodes: account.global_roles === RoleDetail.ADMIN.toLowerCase()  ? ["ALL_POI"] : permissionData.filter((permission: any) => permission.email == account.email).map((permission: any) => permission.entity_code),
        isDefaultPassword: false,
        groups: []
      }
      return user;
    });
  }

  importSsoUsersFromExcel(): void {
    this.setState({ssoExcelFile: null});
    this.setState({ modalContent: null });
    userAdminService.sendSsoFile(this.props.client, this.state.ssoExcelFile!)
      .then(() => this.props.updateData())
      .catch(() => window.alert('erreur dans la création des utilsateurs sso'));
  }

  importStandardUsersFromExcel(): void{
    userAdminService.createUsersFromExcel(this.props.client, this.state.usersToImport);
    this.setState({ modalContent: null });
    this.setState({ usersToImport: [] });
    this.props.updateData()
  }

  public render(): JSX.Element {
    return (
      <div className="rows">
        <div className="searchZone">
          <div>
            <input value={this.state.inputFilter} className="filterSearch" onChange={this.handleSearchFilter} placeholder="Rechercher" type="text" />
          </div>
          <div className="sortPicker" >
            <select onChange={this.handlePasswordFilter}>
              <option hidden value="">Filtrer (mot de passe)</option>
              <option id ="all" value="all">Voir tout (par défaut)</option>
              <option id ="defined" value="defined">Mot de passe défini</option>
              <option id ="undefined" value="undefined">Mot de passe non défini</option>
            </select>
          </div>
          <div className="sortPicker">
            <select onChange={this.handleRoleFilter}>
              <option hidden value="">Filtrer (rôle)</option>
              <option value="all">Voir tout (par défaut)</option>
              <option value="global">Global</option>
              <option value="local">Local</option>
            </select>
          </div>
          <div id={this.state.modalEnabledAction} className="searchButton">
                    <DropdownMenu actionClick={this.handleDropdowns}
                                  buttonText='Importer des utilisateurs' 
                                  enabledActionName={this.state.modalEnabledAction} 
                                  actionMenuList={importUsersOptionList}
                                  disabled={!this.props.client}
                                  ids={this.state.selectedUsers.map((user)=> {return user.id})}
                                  ref={this.dropdownRef}></DropdownMenu>
                </div>
          <div className="searchButton createButton">
            <button className={this.props.client ? "customButton w120 valid-btn" : "customButton w120 disabled"}
              onClick={() => history.push('/user-creation/'+this.props.client, {clientId: this.props.client})}
              disabled={!this.props.client}>Créer Utilisateur</button><br />
          </div>
          <div>
            <div id={this.state.modalEnabledAction} className="searchButton actionButton">
              <DropdownMenu
                actionClick={this.handleDropdowns}
                buttonText="Action" 
                enabledActionName={this.state.modalEnabledAction} 
                actionMenuList={this.props.isUsersActive ? actionActiveMenuList : actionInactiveMenuList}
                disabled={this.state.disableActions}
                ids={this.state.selectedUsers.map((user)=> {return user.id})}
                ref={this.dropdownRef}>
              </DropdownMenu>
            </div>
            { this.state.showModal
                ? <Modal onClose={this.handleDropdowns}
                  onValid={this.state.modalAction} 
                  isShown={this.state.showModal} 
                  headerText={this.state.modalTitle}
                  modalContent={this.state.modalContent}>
                </Modal> : ''
            }
          </div>
        </div>
        <div className="tableContent">
          <table>
            <thead className="tableHead">
              <tr className="tableRow">
                <th scope="col" onChange={()=>this.selectAll(this.state.selectAll)} className="tableCell checkboxCell headerCell">
                  <Checkbox disable={false}/>
                </th>
                <th scope="col" className="tableCell checkboxCell headerCell hidden">
                  <img src={temp_password_icon} className="logo"></img>
                </th>
                <th scope="col" className="tableCell headerCell dataCell">Prénom</th>
                <th scope="col" className="tableCell headerCell dataCell">Nom</th>
                <th scope="col" className="tableCell headerCell dataCell">Mail</th>
                <th scope="col" className="tableCell headerCell dataCell">Dernière Modif.</th>
                <th scope="col" className="tableCell checkboxCell headerCell hidden">
                  <img src={modify_icon} className="logo"></img>
                </th>
                <th scope="col" className="tableCell checkboxCell headerCell hidden">
                  <img src={delete_cross} className="logo"></img>
                </th>
              </tr>
            </thead>
            <tbody className="tableBody" key={this.props.key}>
              { this.state.currentList && this.state.currentList.map((row: any) => (
                <tr key={row.user.id} className="tableRow">
                  <td scope="col" id={row.user.id} className="tableCell checkboxCell" onChange={() =>this.select(row.user.id)}>
                    <Checkbox disable={false} checkedValue={row.select} />
                  </td>
                  <td scope="col" id={row.user.id} className="tableCell checkboxCell">
                    <img src={row.user.isDefaultPassword ? temp_password_icon : valid_password_icon} className="logo"></img>
                  </td>
                  <td scope="col" id={row.user.id} className="tableCell dataCell">{row.user.firstName}</td>
                  <td scope="col" id={row.user.id} className="tableCell dataCell">{row.user.lastName}</td>
                  <td scope="col" id={row.user.id} className="tableCell dataCell">{row.user.mail}</td>
                  <td scope="col" id={row.user.id} className="tableCell dataCell">{row.user.lastUpdate.toLocaleDateString()}</td>
                  <td scope="col" id={row.user.id} className="tableCell checkboxCell">
                    <button className="iconBtn">
                      <img src={modify_icon} className="logo" onClick={() => history.push('/user-creation/'+this.props.client, {clientId: this.props.client, userId: row.user.id})}/>
                    </button>
                  </td>
                  <td scope="col" id={row.user.id} className="tableCell checkboxCell">
                    <button className="iconBtn">
                      <img src={delete_cross} className="logo"
                        onClick={()=>{
                          row.select = true;
                          this.handleDropdowns("Supprimer un utilisateur")}
                        }/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}



export default UserAdminListFragment;