import React from 'react';
import './Login.scss'
import evermaps_icon from '../../assets/img/Logo_favicon_evermaps.png'
import { Trans } from "react-i18next";
import userAdminService from '../services/user-admin.service';
import routingService from './../services/routing.service'

export interface Props {
  username?: string;
  password?: string;
  error?: boolean;
  loading?: boolean;
}

interface State{
  username: string;
  password: string;
  inputType: string;
  isMailValid: boolean;
  errorMessage: string;
  buttonStyle: string;
  loginId: string;
  isLoading: boolean;
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {  username : '', 
                    password: '',
                    inputType: "text",
                    isMailValid: true,
                    errorMessage: "Veuillez renseigner un e-mail valide.",
                    buttonStyle: "customButton loginButton disabled right w120 mg-right40",
                    loginId: "username",
                    isLoading: this.props.loading || false};
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    }

    handleInputOnChange = (input: any) => {

      let inputValue = input.target.value

      switch(this.state.loginId) {
        case "username" :
          // mail pattern
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
          if ( re.test(inputValue) ) {
              this.setState({
                isMailValid: true,
                username : inputValue,
                buttonStyle: "customButton loginButton valid-btn right w120 mg-right40"})
          }
          else {
              // invalid email, maybe show an error to the user.
              this.setState({
                isMailValid: false,
                username: inputValue,
                buttonStyle: "customButton loginButton disabled right w120 mg-right40",
                errorMessage: "Veuillez renseigner un e-mail valide."
              })
          }
          break;

        case "password" :
          if(inputValue !== "") {
            this.setState({
              buttonStyle: "customButton loginButton valid-btn right w120 mg-right40"
            })
          }
          else {
            this.setState({
              buttonStyle: "customButton loginButton disabled right w120 mg-right40"
            })
          }
          this.setState({
            password: inputValue
          })
      }      
    }

    buttonAction = (action: any) => {
      let push = action.target.id
      switch(push){
        case "username":
          let isUserExist = true
          if(isUserExist) {
            this.setState({
              loginId : "password",
              buttonStyle: "customButton loginButton disabled right w120 mg-right40",
              inputType: "password",
            })
          }
          break;

          case "password":
            this.login(this.state.username, this.state.password)
            this.setState({
              buttonStyle: "customButton loginButton disabled right w120 mg-right40",
            })
          break;
      }
    }

    login(inputUsername: string, inputPassword: string) {
      userAdminService.login(inputUsername, inputPassword)
      .then(response => {
        if(response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data))
          routingService.navigateToProtected('/user-administration');
        }
      })
      .catch(error => {
          this.setState({
            loginId : "username",
            buttonStyle: "customButton loginButton disabled right w120 mg-right40",
            isMailValid : false,
            errorMessage: "L'utilisateur et/ou le mot de passe sont incorrect.", 
            inputType: "text",
          })
      });
    }

  render(){
    return (
        <div id="login-modal" className="login-background center">
          <div>
            <div className="rows">
              <div className="login-icon">
                <img src={evermaps_icon}></img>
              </div>
              <div className="login-title">
                <h1><Trans>loginPage.title</Trans></h1>
              </div>
              <div className="login-form">
                <div className="login-input">
                  <div className="login-label">
                    {
                      this.state.loginId === "password" ? <h2><Trans>loginPage.password.input</Trans></h2> :<h2><Trans>loginPage.login.input</Trans></h2> 
                    }
                </div>

                  <input id="username" value={this.state.username} className={this.state.loginId === "username" ? (this.state.isMailValid ? "" : "error") : "hide"}
                   type="username" name="username" onChange={this.handleInputOnChange} readOnly={!(this.state.loginId === "username")}/>
                  <input id="password" value={this.state.password} className={this.state.loginId === "username" ? "hide":""}
                   type="password" name="password" onChange={this.handleInputOnChange} readOnly={(this.state.loginId === "username")}/>

                  {this.state.isMailValid || this.state.loginId !== "username" ? '' : <div className="msg-error">{this.state.errorMessage}</div>}

                  <div className="link"> <a href="https://evermaps.com">
                    {
                      this.state.loginId === "username" ?
                        <Trans>loginPage.forgottenMail</Trans> :
                        <Trans>loginPage.forgottenPassword</Trans>
                    }</a></div>
                  {
                    this.state.loginId === "username" ?
                    <div className="checkbox">
                      <input type="checkbox" />
                      <label><Trans>loginPage.rememberMe</Trans></label>
                    </div> :
                    ""
                  }
                </div>
                <button id={this.state.loginId} className={this.state.buttonStyle} disabled={!this.state.isMailValid || this.state.username === ""}
                onClick={this.buttonAction}> {this.state.loginId === "username" ? 'Suivant': 'Se connecter'}</button>
              </div>
              <div className="footer"><Trans>loginPage.footer</Trans></div>
            </div>
          </div>
        </div>
      );
    }
}

export default Login;