import React from 'react';
import './User-Admin-Interface.scss'
import { Trans } from "react-i18next";
import UserAdminService from '../../services/user-admin.service'
import Guid from '../../toolbox/guid'
import history from '../../history'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Account } from '../../data/models/account/account.model';
import { AccountUser, UserRole } from '../../data/models/AccountUser/user.model';
import Tabs from '../components/custom-components/tabs panel/tabs.component';
import Tab from '../components/custom-components/tabs panel/tab.component';
import UserAdminListFragment from '../components/user-admin-list/user-admin-list.component';



export interface PanelProps {
  children?: React.ReactNode;
  value?: any;
  index?: any;
  other?: number[];
}
interface PanelState {}

const PasswordFilter = {
  ALL: 1,
  DEFINED: 2,
  UNDEFINED: 3,
}

const RoleFilter = {
  ALL: 1,
  GLOBAL: 2,
  LOCAL: 3,
}

export class TabPanel extends React.Component<PanelProps, PanelState> {
  render(){
    const { children, value, index, ...other } = this.props;
    return (   
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${this.props.index}`}
        aria-labelledby={`simple-tab-${this.props.index}`}
        {...this.props.other}
      >
        {this.props.value === this.props.index && (
          <Box p={3}>
            <Typography>{this.props.children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

export interface Props {
  accountList?: Account[];
  value?: number;
  clientInfos?: any;
}

interface State {
  currentAccountList: Account[];
  selectedClientId: string;
  setValue: number;
  setInfos: {select:boolean,user:AccountUser}[];
  setActiveUsers: {select:boolean,user:AccountUser}[];
  setInactiveUsers: {select:boolean,user:AccountUser}[];
  currentActiveUsers: {select:boolean,user:AccountUser}[];
  currentInactiveUsers: {select:boolean,user:AccountUser}[];
  selectAll: boolean;
  passwordFilter: number;
  roleFilter: number;
  showModal: boolean;
  modalAction?: () => void;
  modalEnabledAction: string;
  modalTitle: string;
  modalContent: any;
}
class UserAdminInterface extends React.Component<Props, State> {
  historyState: any = history.location.state;
  UserListRef: React.RefObject<UserAdminListFragment>;

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentAccountList: this.props.accountList || [],
      selectedClientId: this.historyState ? this.historyState.clientId : '',
      setValue: this.props.value || 0,
      setInfos : this.props.clientInfos || [],
      setActiveUsers : this.props.clientInfos || [],
      setInactiveUsers : this.props.clientInfos || [],
      currentActiveUsers: this.props.clientInfos || [],
      currentInactiveUsers: this.props.clientInfos || [],
      passwordFilter: PasswordFilter.ALL,
      roleFilter: RoleFilter.ALL,
      selectAll: true,
      showModal: false,
      modalEnabledAction: '',
      modalTitle: '',
      modalContent: ''
    };
    
    this.UserListRef = React.createRef();

    this.init = this.init.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.createData = this.createData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClients = this.handleClients.bind(this);
    this.init();
    if (this.state.selectedClientId !== '') {
      this.loadClients(this.state.selectedClientId);
    }
  }

  public init(): void {
    UserAdminService.getAllClient().then((response)=>{
      this.setState({
        currentAccountList: response.data.payload.data.map((acc: any) => {
          let account = new Account();
          account.id = acc.id;
          account.name = acc.name;
          account.description = acc.description;
          return account;
        })
      });
    })
  }


  public createData(
    id: string, defaultPassword: string, firstName: string, lastName: string,
    mail: string, role: string, targets: string[],  lastModifDate: string
  ): AccountUser {
    let user = new AccountUser();
    user.id = id;
    user.firstName = firstName;
    user.lastName = lastName;
    user.mail = mail;
    user.lastUpdate = new Date(lastModifDate);
    user.status = defaultPassword;
    user.role = new UserRole();
    user.role.scope = role;
    user.role.targets = targets;
    return user;
  }

  public handleClients(): void {
    let id = (document.getElementById("clientList") as HTMLSelectElement).value;
    this.loadClients(id);
  }

  public loadClients(clientId: string): void {
    if (Guid.isGuid(clientId))
      this.setState({ selectedClientId: clientId });
      UserAdminService.getUsersFromClient(clientId).then((resp) => {
        if (resp.data.payload) {
          this.setState({ setInfos: resp.data.payload.data.map((user:any) => {
            let usr = new AccountUser();
            usr.id = user.id;
            usr.firstName = user.firstname;
            usr.lastName = user.lastname;
            usr.mail = user.email;
            usr.username = user.username;
            usr.status = user.status;
            usr.role = new UserRole();
            usr.isEnabled = user.isEnabled;
            usr.role.scope = user.role.scope;
            usr.role.targets = user.role.targets;
            usr.storeCodes = user.role.resources.POI
            usr.groups = user.role.resources.POI_GROUP
            usr.lastUpdate = new Date(user.updateDate);
            return {select: false, user: usr};
          })
        })
        } else {
          this.setState({
            setInfos: [],
            setActiveUsers: [],
            setInactiveUsers: []
          });
        }
        
        this.setState({
          setActiveUsers: this.state.setInfos.filter(user => user.user.isEnabled),
          setInactiveUsers: this.state.setInfos.filter(user => !user.user.isEnabled),
          currentActiveUsers: this.state.setInfos.filter(user => user.user.isEnabled),
          currentInactiveUsers: this.state.setInfos.filter(user => !user.user.isEnabled)
        });

        this.UserListRef.current?.clientHasChanged();
    })
  }

  public handleChange(event: any): void {
    this.setState({...this.state, setValue: event.target.value});
  }

  public render(): JSX.Element {
    return (
      <div>
        <div className="background-admin">
          <div className="frame">
            <div className="rows">
              <div className=" header">
                <div className="title padlr20">
                  <h1><Trans>adminPage.title</Trans></h1>
                </div>
                <div className="clientAccounts">
                  <select id="clientList" onChange={this.handleClients} value={this.state.selectedClientId !== '' ? this.state.selectedClientId : "default"}>
                    <option hidden value="default">Compte Client</option>
                    {
                      this.state.currentAccountList.map(acc => {
                        return <option key={acc.id} value={acc.id}>{acc.name}</option>
                      })
                    }
                    <i className="material-icons">keyboard_arrow_down</i>
                  </select>
                </div>
              </div>
              <div>
                <div className="tabs">
                  <Tabs>
                    <Tab title="Compte Actif">
                      <UserAdminListFragment
                        ref={this.UserListRef}
                        list={this.state.currentActiveUsers}
                        isUsersActive={true}
                        key="active"
                        client={this.state.selectedClientId}
                        updateData={()=>this.loadClients(this.state.selectedClientId)}>
                      </UserAdminListFragment>
                    </Tab>
                    <Tab title="Compte Inactif">
                      <UserAdminListFragment
                        ref={this.UserListRef}
                        list={this.state.currentInactiveUsers}
                        isUsersActive={false}
                        key="inactive"
                        client={this.state.selectedClientId}
                        updateData={()=>this.loadClients(this.state.selectedClientId)}>
                      </UserAdminListFragment>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default UserAdminInterface;