import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Home from './home/Home';
import history from './history';
import UserAdminInterface from './pages/user-admin-interface/User-Admin-Interface'
import UserCreateUpdate from './pages/user-create-update/User-Create-Update'
import AuthRoute from './AuthRoute'

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/user-administration" exact component={UserAdminInterface} />
                    <Route path="/user-creation/:clientId" exact component={UserCreateUpdate} />
                    <Route path="/user-update" exact component={UserCreateUpdate} />
                    {/* <AuthRoute path="/user-administration" exact={true} component={UserAdminInterface} />
                    <AuthRoute path="/user-creation" exact={true} component={UserCreateUpdate} />
                    <AuthRoute path="/user-update" exact={true} component={UserCreateUpdate} /> */}
                </Switch>
            </Router>
        )
    }
}