import React from 'react';
import './DropdownMenu.scss'

export interface Props {
  ids?: string[],
  buttonText?: string,
  actionMenuList: string[],
  actionClick?: (name: string) => void;
  enabledActionName?: string;
  disabled: boolean;
}
interface State {
  showMenu: boolean
  disabled: boolean
}
export default class DropdownMenu extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    
    this.state = {
      showMenu: false,
      disabled: this.props.disabled
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.actionClick = this.actionClick.bind(this);
  }
  
  showMenu(event: any) {
    event.preventDefault();
    
    this.setState({ showMenu: true });
  }
  
  closeMenu(event: any) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });  
  }

  actionClick(action: string) {
    if(this.props.actionClick) {
      this.props.actionClick(action);
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  render() {
    return (
      <div>
          <button className={!this.props.disabled ? "actionBtn customButton valid-btn w120" : "actionBtn customButton disabled w120"}
                  onClick={!this.state.showMenu ? this.showMenu : this.closeMenu}
                  disabled={this.props.disabled}>
          {this.props.buttonText}
            <i className="material-icons">keyboard_arrow_down</i>
          </button>
        
        {
          this.state.showMenu
            ? (
              <div
                className="dropdownMenu"
              >
                {
                  this.props.actionMenuList.map((action)=>(
                    <button className="dropdownBtn" onClick={()=>this.actionClick(action)}>{action}</button>
                  ))
                }
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}