import React from 'react'

interface Props{
  title: string;
  children:any;
}

interface State{
    currentTitle: string;
}

class Tab extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
    }
    render(){
        return (<div>{this.props.children}</div>);
    }
  
  }
  
export default Tab;

// const Tab: React.FC<Props> = ({ children }) => {
//   return <div>{children}</div>
// }

// export default Tab
