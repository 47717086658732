import React from 'react';
import './User-Create-Update.scss'
import Modal from './../components/custom-components/modal/modal';
import AddPoiModal from '../components/modals/add-poi-modal/Add-Poi-Modal'
import history from '../../history'
import Checkbox from '../components/custom-components/checkbox/Checkbox';
import {AccountUser, UserRole} from '../../data/models/AccountUser/user.model';
import userAdminService from '../../services/user-admin.service';
import Guid from '../../toolbox/guid';
import UserAdminService from "../../services/user-admin.service";


const UserRoleList = {
    LOCAL: 1,
    GROUP: 2,
    GLOBAL: 3,
};

export interface Props {
    accountUser?: AccountUser;
}

interface State {
    showPoiModal: boolean;
    showGroupModal: boolean;
    clientId: string;
    userId?: string;
    selectedRole: number;
    userRoleDetail: any;
    poiList: string[];
    groupList: string[];
    currentPoiList: string[];
    currentGroupList: string[];
    newAccountUser: AccountUser;
    disableButtons: boolean;
}

interface Right {
    Name: string;
    Label: string;
}
class UserCreateUpdate extends React.Component<Props, State> {
    historyState: any = history.location.state;
    optionalBtn = [{
        btnTitle: 'Tout supprimer',
        btnStyle: 'customButton margin5 red-btn w120'
    }];

    constructor(props: Props) {
        super(props);
        const initialUser = this.props.accountUser || new AccountUser();

        initialUser.firstName = initialUser.firstName || "";
        initialUser.lastName = initialUser.lastName || "";
        initialUser.mail = initialUser.mail || "";
        this.state = {
            showPoiModal: false,
            showGroupModal: false,
            clientId: this.historyState.clientId,
            userId: this.historyState.userId || '',
            selectedRole: 0,
            userRoleDetail: [],
            poiList: [],
            groupList: [],
            currentPoiList: [],
            currentGroupList: [],
            newAccountUser: initialUser,
            disableButtons: false
        }
        this.handlePoiOpen = this.handlePoiOpen.bind(this);
        this.handleRoleSelection = this.handleRoleSelection.bind(this)
        this.handleUserFirstName = this.handleUserFirstName.bind(this);
        this.handleUserLastName = this.handleUserLastName.bind(this);
        this.handleUserMail = this.handleUserMail.bind(this);
        this.validateData = this.validateData.bind(this);
        this.updateRoleDetails = this.updateRoleDetails.bind(this);
        this.initUserData = this.initUserData.bind(this);

    }

    componentDidMount() {
        this.initUserData();
    }

    fetchUserRights = async () => {
        try {
            const response = await UserAdminService.getRights();
            const userRoleDetailList = response.data.payload.data.map((right: Right) => ({
                id: right.Name,
                label: right.Label,
                selected: false,
                enable: true
            }));
            this.setState({
                userRoleDetail: userRoleDetailList
            });
        } catch (error) {
            console.error('Error fetching user rights:', error);
        }
    }
    initUserData = async () => {
        await this.fetchUserRights();
        if (this.state.userId && Guid.isGuid(this.state.userId)) {
            try {
                const resp = await UserAdminService.getUserFromClient(this.state.clientId, this.state.userId);
                const user = resp.data.payload.data;
                const usr = new AccountUser();
                usr.id = user.id;
                usr.firstName = user.firstname;
                usr.lastName = user.lastname;
                usr.mail = user.email;
                usr.username = user.username;
                usr.status = user.status;
                usr.role = new UserRole();
                usr.role.scope = user.role.scope;
                usr.role.targets = user.role.targets;
                usr.storeCodes = user.role.resources.POI;
                usr.groups = user.role.resources.POI_GROUP;
                usr.isSso = user.isSSO;

                const updatedUserRoleDetail = this.state.userRoleDetail.map((detail: any) => {
                    const isSelected = usr.role.targets.includes(detail.id);
                    return { ...detail, selected: isSelected };
                });

                this.setState({
                    newAccountUser: usr,
                    poiList: usr.storeCodes,
                    groupList: usr.groups,
                    currentPoiList: usr.storeCodes,
                    currentGroupList: usr.groups,
                    selectedRole: usr.role.scope === "local"
                        ? UserRoleList.LOCAL
                        : usr.role.scope === "group"
                            ? UserRoleList.GROUP
                            : UserRoleList.GLOBAL,
                    userRoleDetail: updatedUserRoleDetail
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        } else {
            const user = this.state.newAccountUser;
            user.role = new UserRole();
            user.storeCodes = [];
        }
    }

    handlePoiOpen = (event: any) => {
        this.setState({showPoiModal: !this.state.showPoiModal});
    }

    handleGroupOpen = (event: any) => {
        this.setState({showGroupModal: !this.state.showGroupModal});
    }

    handleUserFirstName = (event: any) => {
        let user = this.state.newAccountUser;
        user.firstName = event.target.value;
        this.setState({
            newAccountUser: user
        })
    }

    public handleUserLastName = (event: any) => {
        let user = this.state.newAccountUser;
        user.lastName = event.target.value;
        this.setState({newAccountUser: user});
    }

    public handleUserMail = (event: any) => {
        let user = this.state.newAccountUser;
        user.mail = event.target.value;
        this.setState({newAccountUser: user});
    }

    public handleRoleSelection = (event: any) => {
        let index = event.target.options.selectedIndex;
        let role = '';
        if (index === UserRoleList.LOCAL) {
            role = 'local';
        } else if (index === UserRoleList.GLOBAL) {
            role = 'global';
        } else if (index === UserRoleList.GROUP) {
            role = 'group';
        } else {
            role = '';
        }

        let user = this.state.newAccountUser;
        user.role.scope = role;
        this.setState({
            selectedRole: index,
            newAccountUser: user
        });
    }

    public handleRoleDetailSelection = (detailId: any) => {
        this.setState({
            userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                if (detail.id === detailId.id) {
                    detail.selected = !detail.selected;
                }
                return detail;
            })
        }, () => this.updateRoleDetails(detailId));
    }

    updatePoiList = () => {
        this.setState({
            poiList: this.state.currentPoiList
        })
    }

    updateCurrentPoiList = (newList: string[]) => {
        this.setState({
            currentPoiList: newList
        })
    }

    updateCurrentGroupList = (newList: string[]) => {
        this.setState({
            currentGroupList: newList
        })
    }

    updateGroupList = () => {
        this.setState({
            groupList: this.state.currentGroupList
        })
    }

    public updateRoleDetails(detailId: any): void {
        if (detailId.id === 'Admin') {
            if (detailId.selected) {
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        if (detail.id !== 'Admin') {
                            detail.enable = false;
                        }
                        return detail;
                    })
                });
            } else {
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        detail.enable = true;
                        return detail;
                    })
                });
            }
        } else if (detailId.id === 'Franchise') {
            if (detailId.selected) {
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        if (detail.id !== 'Franchise') {
                            detail.enable = false;
                        }
                        return detail;
                    })
                });
            } else {
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        detail.enable = true;
                        return detail;
                    })
                });
            }
        } else if (detailId.id === 'Gmb' || detailId.id === 'Campaign') {
            if (detailId.selected) {
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        if (detail.id !== 'Gmb' && detail.id !== 'Campaign') {
                            detail.enable = false;
                        }
                        return detail;
                    })
                });
            } else {
                let otherDetail: any
                if (detailId.id === 'Gmb') {
                    this.state.userRoleDetail.map((detail: any) => {
                        if (detail.id === 'Campaign') {
                            otherDetail = detail;
                        }
                    })
                } else if (detailId.id === 'Campaign') {
                    this.state.userRoleDetail.map((detail: any) => {
                        if (detail.id === 'Gmb') {
                            otherDetail = detail;
                        }
                    })
                }
                this.setState({
                    userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                        if (otherDetail.selected) {
                            if (detail.id !== 'Gmb' && detail.id !== 'Campaign') {
                                detail.enable = false;
                            }
                            return detail;
                        } else {
                            detail.enable = true;
                            return detail;
                        }
                    })
                });
            }
        }
    }

    public validateData(): void {
        let newUser = this.state.newAccountUser;
        let userRoleDetail: string[] = [];

        this.setState({disableButtons: true});
        newUser.role = this.state.newAccountUser.role
        if (this.state.selectedRole === UserRoleList.GLOBAL) {
            newUser.role.scope = 'global';
            newUser.role.targets = ['admin'];
            newUser.storeCodes = ['ALL_POI'];
        } else if (this.state.selectedRole === UserRoleList.LOCAL) {
            newUser.role.scope = 'local';
            this.state.userRoleDetail.map((detail: any) => {
                if (detail.selected) {
                    userRoleDetail.push(detail.id);
                }
            });
            newUser.role.targets = userRoleDetail;
            newUser.storeCodes = this.state.poiList;
            newUser.groups = [];
        } else if (this.state.selectedRole === UserRoleList.GROUP) {
            newUser.role.scope = 'group';
            this.state.userRoleDetail.map((detail: any) => {
                if (detail.selected) {
                    userRoleDetail.push(detail.id);
                }
            });
            newUser.role.targets = userRoleDetail;
            newUser.groups = this.state.groupList;
            newUser.storeCodes = [];
        }

        if (this.state.clientId) {
            if (this.state.userId) {
                userAdminService.updateUser(this.state.clientId, this.state.userId, newUser)
                    .then((resp: any) => {
                        history.push('/user-administration', {clientId: this.state.clientId});
                    }).catch((error) => {
                    console.log(error);
                    history.push('/user-administration', {clientId: this.state.clientId});
                });
            } else {
                userAdminService.createUser(this.state.clientId, newUser)
                    .then((resp: any) => {
                        history.push('/user-administration', {clientId: this.state.clientId});
                    }).catch((error) => {
                    console.log(error);
                    history.push('/user-administration', {clientId: this.state.clientId});
                });
            }
        }
    }

    render() {
        return (
            <div className="background-admin">
                <div className="frame padlr20">
                    <div className="rows">
                        <div className="title">
                            <h1>Créer Utilisateur</h1>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Identité de l'utilisateur</h2>
                            </div>
                            <div className="col">
                                <div className="inputs">
                                    <div className="firstName inputs">
                                        <label className="w237">
                                            Prénom
                                            <input type="text" name="firstName"
                                                   value={this.state.newAccountUser.firstName}
                                                   onChange={this.handleUserFirstName}
                                                   disabled={this.state.userId ? true : false}/>
                                        </label>
                                    </div>
                                    <div className="lastName inputs">
                                        <label className="w237">
                                            Nom
                                            <input type="text" name="name" value={this.state.newAccountUser.lastName}
                                                   onChange={this.handleUserLastName}
                                                   disabled={this.state.userId ? true : false}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="mail inputs">
                                    <label className="w237">
                                        Mail
                                        <input type="text" name="name" value={this.state.newAccountUser.mail}
                                               onChange={this.handleUserMail}
                                               disabled={this.state.userId ? true : false}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Méthode de connexion</h2>
                            </div>
                            <div className="col inputs left">
                                <label className="w237">{this.state.newAccountUser.isSso ? 'SSO' : 'Standard'}</label>
                            </div>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Niveau du rôle</h2>
                            </div>
                            <div className="col inputs left">
                                <select onChange={this.handleRoleSelection}
                                        value={this.state.newAccountUser.role ? this.state.newAccountUser.role.scope : ""}>
                                    <option hidden value="">Veuillez sélectionner</option>
                                    <option id="local" value="local">Local</option>
                                    <option id="group" value="group">Groupe</option>
                                    <option id="global" value="global">Global</option>
                                </select>
                            </div>
                        </div>
                        {(this.state.selectedRole === UserRoleList.LOCAL || this.state.selectedRole === UserRoleList.GROUP)
                            && <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Détail du rôle</h2>
                                </div>
                                <div className="col left">
                                    <div>
                                        {
                                            this.state.userRoleDetail.map((detail: any) => (
                                                <div key={detail.id}>
                                                    <label id={detail.id} className="inputs"
                                                           onChange={() => this.handleRoleDetailSelection(detail)}>
                                                        <Checkbox
                                                            checkedValue={detail.selected}
                                                            disable={!detail.enable}
                                                        />
                                                        {detail.label}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.selectedRole === UserRoleList.LOCAL
                            && <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Sélectionnez les établissements concernés par ce rôle</h2>
                                </div>
                                <div className="">
                                    <div>
                                        <div>{this.state.poiList.length + " établissements sélectionnés"} </div>
                                        <button className="customButton valid-btn w210 margin5"
                                                onClick={this.handlePoiOpen}> Ajouter des établissements
                                        </button>
                                        {this.state.showPoiModal &&
                                            <Modal onClose={this.handlePoiOpen}
                                                   onValid={this.updatePoiList}
                                                   isShown={true}
                                                   headerText="Ajouter des établissements manuellement"
                                                   modalContent={<AddPoiModal updatePoiList={this.updateCurrentPoiList}
                                                                              poiList={this.state.poiList}
                                                                              updateText='Saisissez les store code des établissements (appuyez sur la touche entrée pour valider)'></AddPoiModal>}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.selectedRole === UserRoleList.GROUP
                            && <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Sélectionnez les groupes concernés par ce rôle</h2>
                                </div>
                                <div className="">
                                    <div>
                                        <div>{this.state.groupList.length + " groupes sélectionnés"} </div>
                                        <button className="customButton valid-btn w210 margin5"
                                                onClick={this.handleGroupOpen}> Ajouter des groupes
                                        </button>
                                        {this.state.showGroupModal &&
                                            <Modal onClose={this.handleGroupOpen}
                                                   onValid={this.updateGroupList}
                                                   isShown={true}
                                                   headerText="Ajouter des groupes manuellement"
                                                   modalContent={<AddPoiModal
                                                       updatePoiList={this.updateCurrentGroupList}
                                                       poiList={this.state.groupList}
                                                       updateText='Saisissez les codes des groupes (appuyez sur la touche entrée pour valider)'>
                                                   </AddPoiModal>}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            <div>
                                <button className="customButton cancel-btn w120 margin5 right"
                                        onClick={() => history.push('/user-administration', {clientId: this.state.clientId})}
                                        disabled={this.state.disableButtons}> Annuler
                                </button>
                                <button className="customButton valid-btn w120 margin5 right"
                                        onClick={this.validateData}
                                        disabled={this.state.disableButtons}> Valider
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default UserCreateUpdate;